import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap"
//complementos de firebase
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAmbtW0bOiCTp-mRW78Hb-_HyV4EH6coPo",
    authDomain: "proyecto-vue-2e10e.firebaseapp.com",
    projectId: "proyecto-vue-2e10e",
    storageBucket: "proyecto-vue-2e10e.appspot.com",
    messagingSenderId: "105755006585",
    appId: "1:105755006585:web:a7eb2da7617f1aaac066a8",
    measurementId: "G-KD7K8WKVDL"
};

// Initialize Firebase
initializeApp(firebaseConfig);

createApp(App).use(router).mount('#app')
