<template>
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">Acerca de</router-link> |
      <router-link to="/services">Servicios</router-link> |
      <router-link to="/blog">Blog</router-link> |
      <router-link to="/contact">Contactos</router-link> | 
      <router-link to="/auth">Acceso</router-link> | 
      <router-link to="/firebase">Firebase Auth</router-link> | 
      <router-link to="/social">Social Login</router-link> | 
    </nav>
</template>