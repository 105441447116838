<template>

<div class="container text-center">
  <div class="row">
    <div class="col">
      Columna de la izquierda
    </div>
    <div class="col">
        <form>
            <div class="mb-3">
                <label for="inputEmail1" class="form-label">Email address</label>
                <input type="email" class="form-control" id="inputEmail1" aria-describedby="emailHelp" v-model="email">
                
            </div>
            
            <div class="mb-3">
                <label for="inputPassword1" class="form-label">Password</label>
                <input type="password" class="form-control" id="inputPassword1" v-model="password">
            </div>      
            
            <button type="submit" class="btn btn-primary" @click.prevent="authUser">Submit</button>
        </form>
    </div>
    <div class="col">

      Columna de la derecha

      {{ email }}
      {{ password }}

    </div>
  </div>
</div>

</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import AuthService from '@/services/AuthService';

    let email = ref("")
    let password = ref("")

    const authUser =async () => {
      const auth = new AuthService()
      const success = await auth.login(email.value, password.value)

      if(success){
        alert('Éxito')
      }else{
        alert('Ha fallado el logeo')
      }
    }



</script>
