<template>

    

    <div class="container text-center">
      <div class="row">
        <div class="col">
          Esta pagina es para conectarse en firebase
        </div>
        <div class="col">
            <form>
                <div class="mb-3">
                    <label for="inputEmail1" class="form-label">Email address</label>
                    <input type="email" class="form-control" id="inputEmail1" aria-describedby="emailHelp" v-model="email">
                    
                </div>
                
                <div class="mb-3">
                    <label for="inputPassword1" class="form-label">Password</label>
                    <input type="password" class="form-control" id="inputPassword1" v-model="password">
                </div>      
                
                <button type="submit" class="btn btn-primary" @click.prevent="authUser">Submit</button>

            </form>
        </div>
        <div class="col">
    
            Firebase es una locura de datos
    
        </div>
      </div>
    </div>
    
</template>
    
<script lang="ts" setup>

import {ref} from 'vue'
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'

let email = ref("")
let password = ref("")

const authUser = () => {
    const  auth = getAuth()

    signInWithEmailAndPassword(auth, email.value, password.value).then(() => {
        alert("Exitoso")
    }).catch((error) =>{
        alert("Error: " + error.message)
    })

}
</script>